/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import get from 'lodash/get';
import _ from 'lodash';

import { ImageUrl } from '../../utils/constants'; 
import {applyHighlights} from '../../utils/methods';

import SocialFeedTextArea from '../SocialFeeds/SocialFeedTextarea';

import { InputFieldContainer, StyledTitle, HeaderContainer, ImageContainer, ButtonContainer, CommentButton } from '../SocialFeeds/styles';

import Image from '../Image';

import { editSocialInspiration } from '../../redux/actions';
import { withTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

class EditPostPopUp extends React.Component {
  constructor(props) {
    super(props);
    const showGivenShoutout = !_.isNull(props.feed.shoutout_user_name);
    this.state = {
      description: get(props.feed, 'description', ''),
      notBase64: false,
      mentionedUsers:[],
      users: [],
      showGivenShoutout,
      shoutoutText: null,
      shoutoutImage: null,
      userIdArray: [],
      taggedUser: '',
      shoutoutType: '',
      exemplifyID: null
    }
  }

  componentDidMount() {
    this.checkBase64();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      description: get(nextProps.feed, 'description', ''),
      showGivenShoutout: !_.isNull(nextProps.feed.shoutout_user_name)
    });
    if(this.props.profileImage != nextProps.profileImage) {
      this.checkBase64();
    }
    if(!_.isUndefined(this.props.feed.shoutout_type)) {
      this.extractUserNames();
    }
  }

  extractUserNames = () => {
    const { feed } = this.props;
    if(feed.shoutout_user_name) {
      let span = document.createElement('span');

      const sanitizedHtml = DOMPurify.sanitize(feed.shoutout_user_name);
      const textNode = document.createTextNode(sanitizedHtml);
      span.appendChild(textNode);

      // span.innerHTML = feed.shoutout_user_name;
      let userNameString = span.textContent.split('-');
      let userNameArr = userNameString[0].split(',');
      let userIdArr = [];
      let exemplifyId = [];
      if(!_.isUndefined(feed.mentioned_users)) {
        feed.mentioned_users.map((obj) => userIdArr.push(obj.uid))
        feed.mentioned_users.map((obj) => exemplifyId.push(obj.exemplify_id))
      }

      this.setState({
        taggedUser: userNameString[0],
        shoutoutText: userNameString[1],
        users: userNameArr,
        userIdArray: userIdArr,
        shoutoutImage: feed.recognition_image ? feed.recognition_image : null,
        showGivenShoutout: true,
        mentionedUsers: userIdArr,
        shoutoutType: feed.shoutout_type,
        exemplifyID: exemplifyId[0] ? exemplifyId[0] : feed.exemplify_id
      });
    } else {
      this.setState({
        taggedUser: "",
        shoutoutText: null,
        users: [],
        userIdArray: [],
        shoutoutImage: null,
        showGivenShoutout: false,
        mentionedUsers: [],
        shoutoutType: feed.shoutout_type,
      });
    }
  };

  addInspiration = (e) => {
    this.setState({
      description: e.target.value
    })
  };

  checkBase64() {
    const { profileImage } = this.props;
    if(profileImage) {
      let arr = profileImage.split('/');
      if(arr[0] !== 'profile-Image') {
        this.setState({
          notBase64: false
        })
      }
      else {
        this.setState({
          notBase64: true
        })
      }
    }
  }


  updatePost = () => {
    const { editSocialInspiration, feed, onClose, updationList } = this.props;
    const { description, taggedUser, users, userIdArray, shoutoutText, mentionedUsers, shoutoutType, shoutoutImage, exemplifyID } = this.state;
    let updatedDescription = '';
    updatedDescription = description ? description.trim() : '';
    let shoutoutUserNames = null;

    if(!_.isEmpty(mentionedUsers)) {
      shoutoutUserNames = `${applyHighlights(users, userIdArray, taggedUser)} - ${shoutoutText}`;
    }
    let mentionedUserArray = [];
    for( let index = 0; index < users.length; index++) {
      mentionedUserArray.push({uid: userIdArray[index], fullname: users[index]});
    }
    editSocialInspiration({
      description: updatedDescription,
      id: feed.id,
      shoutout_user_name: shoutoutUserNames,
      recognition_image: shoutoutImage ? `${shoutoutImage}` : null,
      shoutout_type: shoutoutType,
      mentioned_users: mentionedUserArray,
      exemplify_id: exemplifyID,
      shoutout: {
        exemplifies: mentionedUsers
      }
    }, updationList);

    this.setState({
      description: get(this.props.feed, 'description', ''),
      showGivenShoutout: false
    });
    onClose();
  };

  updateText = (text, userId, taggedUserName, bool) => {
    const { userIdArray, users, taggedUser } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let taggedUsers = taggedUser;
    if(userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if(!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else 
        taggedUsers = taggedUserName;
    }

    this.setState({
      description: text,
      userIdArray: userIdArr,
      users: userNameArr,
      taggedUser: taggedUsers,
      showGivenShoutout: bool,
      mentionedUsers: userIdArr,
    });
  };

  updateShoutoutArray = (mentionedUsers, text, image, exemplify_id) => {
    this.setState({
      mentionedUsers: mentionedUsers,
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplify_id ? exemplify_id : null,
      exemplifyID: exemplify_id ? exemplify_id : null
    });
  };

  onRemoveShoutout = () => {
    this.setState({
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      mentionedUsers: [],
      shoutoutType: 0
    });
  };

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: [],
      mentionedUsers: [],
      shoutoutType: 0,
    });
  };

  togglePostButton = (value) => {
    const { shoutoutText, userIdArray } = this.state;
    if(userIdArray.length !== 0) {
      if(userIdArray.length !== 0 && !_.isNull(shoutoutText)) {
        return false;
      } else 
        return true;
    } else if(value !== null && value.trim() !== ''){
      return false;
    }
    return true;
  };

  render() {
    const { showModal, feed, profileImage, enableTrigger, t } = this.props;
    const { notBase64, description } = this.state;
    return(
      <Modal show={showModal} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <StyledTitle>{t("EDIT POST")}</StyledTitle>
        </Modal.Header>
        <Modal.Body>
          <HeaderContainer onModal>
            { notBase64 ?
              <Image
                image={profileImage}
                alt="user"
              /> : <img src={profileImage} alt="user" />
            }
            <InputFieldContainer>
              <SocialFeedTextArea
                userCompany={this.props.userCompany}
                addInspiration= {this.addInspiration}
                inspirationQuote={description}
                updateText={this.updateText}
                users={this.state.users}
                userIdArray={this.state.userIdArray}
                placeholder={t("Say something about this post...")}
                showGivenShoutout={this.state.showGivenShoutout}
                updateShoutoutArray={this.updateShoutoutArray}
                onRemoveShoutout={this.onRemoveShoutout}
                shoutoutText={this.state.shoutoutText}
                shoutoutImage={this.state.shoutoutImage}
                taggedUser={this.state.taggedUser}
                onRemoveUser={this.onRemoveUser}
                enableTrigger={enableTrigger}
              />
            </InputFieldContainer>
          </HeaderContainer>
          { feed.image && 
            <ImageContainer>
              <img src={`${ImageUrl}/${feed.image}`} alt="uploadable" />
            </ImageContainer>
          }
          <ButtonContainer>
            <CommentButton onClick={this.updatePost} disabled={this.togglePostButton(description)}>{t("Update Post")}</CommentButton>
          </ButtonContainer>
        </Modal.Body>
      </Modal>
    );
  }
}

EditPostPopUp.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  feed: PropTypes.any,
  editSocialInspiration: PropTypes.func,
  updationList: PropTypes.string.isRequired,
  profileImage: PropTypes.string,
  userCompany: PropTypes.object.isRequired,
  enableTrigger: PropTypes.bool,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  userCompany: state.profileData.userCompany,
});

const mapDispatchToProps = (dispatch) => ({
  editSocialInspiration: (postDetails, updationList) => dispatch(editSocialInspiration(postDetails, updationList))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditPostPopUp));